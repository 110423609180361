/**
 * Created by Bart Decorte
 * Date: 06/04/2018
 * Time: 11:56
 */

function RequestV2(endpoint, method, data, onSuccess, onError, onComplete)
{
  Request.call(this, endpoint, method, data, onSuccess, onError, onComplete);
  this.apiPrefix = 'api/v2';
}

RequestV2.prototype = Object.create(Request.prototype);
define("client/RequestV2", function(){});

