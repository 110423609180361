/**
 * Created by Bart Decorte
 * Date: 05/02/2018
 * Time: 13:48
 */

function NewsletterController() {
  this.endpoint = 'newsletter';
}

NewsletterController.prototype.post = function(email, fullName, firstName, name, onSuccess, onError) {
  var scope = this;

  onSuccess = typeof onSuccess === typeof undefined ? function() {} : onSuccess;
  var success = function(data) {
    scope.gtmNewsletterSubscribe();
    onSuccess(data);
  };

  onError = typeof onError === typeof undefined ? function() {} : onError;
  var error = function(errors) {
    onError(errors);
  };

  var request = new Request(scope.endpoint, 'post', {
    email: email,
    full_name: fullName,
    first_name: firstName,
    name: name
  }, success, error);
  request.execute();
};

NewsletterController.prototype.gtmNewsletterSubscribe = function() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'formSubmission',
    'formTitle': 'Newsletter'
  });
};

define("client/NewsletterController", function(){});

