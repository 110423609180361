/**
 * Created by Bart Decorte
 * Date: 19/12/2017
 * Time: 11:27
 */

function Cart() {
  var scope = this;

  this.templates = {};
  this.templates.cart = Handlebars.compile($('#cart-template').html());

  this.bindListeners();
}

Cart.prototype.bindListeners = function () {
  var scope = this;
  var $body = $('body');
  $body.on('click', '.js-add-one-to-cart', function(ev) { return scope.addOneToCartClicked(ev); });
  $body.on('change', '.js-quantity-input:not(.js-quantity-input-synchronous)', function(ev) { return scope.quantityChanged(ev); });
  $body.on('click', '.js-delete-from-cart', function(ev) { return scope.deleteFromCart(ev); });
  $body.on('change', '.js-cart-remarks', function(ev) { return scope.remarksChanged(ev); });
};

Cart.prototype.addOneToCartClicked = function(ev) {
  ev.preventDefault();
  var $elt = $(ev.target).hasClass('js-add-one-to-cart') ? $(ev.target) : $(ev.target).parents('.js-add-one-to-cart')
      .first();

  var productId = parseInt($elt.data('product-id'));
  esign.cartController.add(productId, 1, {});

  return false;
};

Cart.prototype.quantityChanged = function(ev) {
  ev.preventDefault();

  var $elt = $(ev.target);
  var cartIndex = parseInt($elt.parents('[data-cart-index]').first().data('cart-index'));
  var val = parseInt($elt.val());
  val = (isNaN(val) || val < 1) ? 1 : val;
  esign.cartController.update(cartIndex, val);
};

Cart.prototype.deleteFromCart = function(ev) {
  ev.preventDefault();
  var $button = $(ev.target);
  var index = parseInt($button.parents('[data-cart-index]').first().data('cart-index'));

  esign.cartController.delete(index);
};

Cart.prototype.draw = function(products, totalFormatted) {
  var scope = this;
  var html = scope.templates.cart({items: products, totalFormatted: totalFormatted});
  $('.cart-content').html(html);
};

Cart.prototype.setCount = function(count) {
  var $cartCounters = $('.js-cart-count');
  var i, $cartCounter;
  for (i = 0; i < $cartCounters.length; i++) {
    $cartCounter = $($cartCounters[i]);
    $cartCounter.text(count);
  }
};

Cart.prototype.remarksChanged = function(ev) {
  var scope = this;
  var $textArea = $(ev.target);
  var text = $textArea.val();
  esign.cartController.updateRemarks(text);
};
define("client/Cart", function(){});

