/**
 * Created by Bart Decorte
 * Date: 10/01/2018
 * Time: 14:25
 */

function CheckoutIndex() {
  var scope = this;

  scope.orderSummary = new OrderSummary();
  scope.bindListeners();
  scope.updateShippingAddress();
}

CheckoutIndex.prototype.bindListeners = function() {
  var scope = this;
  var $body = $('body');
  $body.on('change', '.js-same-address', function(ev) { return scope.lSameAddressChanged(ev); });
  $body.on('change', '.js-shipping-method', function(ev) { return scope.lShippingMethodChanged(ev); });
};

CheckoutIndex.prototype.lSameAddressChanged = function(ev) {
  var scope = this;
  scope.updateShippingAddress();
};

CheckoutIndex.prototype.lShippingMethodChanged = function(ev) {
  var scope = this;
  scope.updateShippingAddressWrap();
  scope.updateShippingAddress();
};

CheckoutIndex.prototype.shippingMethodRequiresAddress = function() {
  var scope = this;
  var $input = $('input[name="shipping_method_id"][value="' + scope.orderSummary.shippingMethodId + '"]');
  return $input.is('[data-requires-shipping-address]');
};

CheckoutIndex.prototype.updateShippingAddressWrap = function() {
  var scope = this;
  var $wrap = $('.js-delivery-address-wrap');
  $wrap.hide();
  if (scope.shippingMethodRequiresAddress()) {
    $wrap.show();
  }
};

CheckoutIndex.prototype.updateShippingAddress = function() {
  var scope = this;
  var $targetInputs = $('.delivery-address');

  $targetInputs.hide();
  if (!scope.orderSummary.sameAddress && scope.shippingMethodRequiresAddress()) {
    $targetInputs.show();
  }
};
define("client/CheckoutIndex", function(){});

