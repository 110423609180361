window.esign = window.esign || {};

esign.cacheSelectors = function() {
  esign.cache = {
    // general
    $html: $('html'),

    // navigation
    $nav: $('.main-nav__wrap')

  };
};

esign.init = function() {

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752,0],
    lazy: true
  });

  // esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();
  //esign.formAjax();
  esign.initPromoAction();
  esign.productPage();
  esign.fancybox();
  esign.lang();
  esign.checkout();
  esign.accordion();
  esign.initCart();
  esign.initOrderSummary();
  esign.initNewsletter();

  esign.pageSpecific();
  esign.social();
  esign.jsonFetcher();
};

esign.pageSpecific = function() {
  var bodyClasses = $('body').attr('class').split(' ');

  var i, bodyClass, className;
  for (i = 0; i < bodyClasses.length; i++) {
    bodyClass = bodyClasses[i];
    if (bodyClass.length) {
      className = bodyClass.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
      className = className.charAt(0).toUpperCase() + className.slice(1);
      if (typeof window[className] !== typeof undefined) new window[className]();
    }
  }
};

esign.social = function() {
      /*
      * Esign Social Feed
      *
      * API:
      *   change social key
      * TYPES:
      *   enable ajax calls
      * USE_GRID:
      *   true requires GRID
      *   false requires feed ajax call
      * GRID:
      *   set order of types
      * GRID_REPEAT:
      *   how many times
      * GRID_REPEAT_EXCLUDE:
      *   exclude types from repeat
      *
      */

      window.social = window.social || {};

      social.cacheSelectors = function () {

        social.data = {
          feed: null,
          instagram: null,
          twitter: null,
          facebook: null,
          pinterest: null
        };

        social.cache = {
          // const
          API: 'https://apps.esign.eu/social/146/',
          TYPES: {
            feed: false,
            instagram: false,
            twitter: false,
            facebook: false,
            pinterest: false
          },
          USE_GRID: true,
          GRID: [
            'instagram',
          ],
          GRID_REPEAT: 12,
          GRID_REPEAT_EXCLUDE: [
                // 'follow'
          ],

          // vars
          typesActive: 0,
          typesCalled: 0,

          // elements
          $socialGridTemplates: $('#social-grid-templates')
        };

        social.cache.$feedTemplate = social.cache.$socialGridTemplates.find('.feed-template');
        social.cache.$instagramTemplate = social.cache.$socialGridTemplates.find('.instagram-template');
        social.cache.$twitterTemplate = social.cache.$socialGridTemplates.find('.twitter-template');
        social.cache.$facebookTemplate = social.cache.$socialGridTemplates.find('.facebook-template');
        social.cache.$pinterestTemplate = social.cache.$socialGridTemplates.find('.pinterest-template');

      };

      social.init = function () {

        // check if social grid exists
        var $socialGridHolder = $('#social-grid-holder');
        if ($socialGridHolder.length) {

          social.cacheSelectors();
          social.cache.$holder = $socialGridHolder;

          // api calls
          social.getData();

        }

      };

      social.showItems = function () {

        if(!social.cache.USE_GRID) {

          if(social.data.feed) {
            for(var i=0, j=social.data.feed.length; i < j; i++){
              social.addItem(social.data.feed[i]);
            };
          }

        } else {

          for (var repeat=0; repeat < social.cache.GRID_REPEAT; repeat++) {

            for(var tI = 0, tJ = social.cache.GRID.length; tI < tJ; tI++) {

              var type = social.cache.GRID[tI],
                show = true;

              if (repeat > 0 && social.cache.GRID_REPEAT_EXCLUDE.indexOf(type) >= 0) {
                show = false;
              }

              if (show) {
                social.showGridItem(type);
              }

            };
          };

        }

      };

      // show item from template
      social.showGridItem = function (type) {

          var socialData = social.data[type];
          if(socialData && socialData.length > 0) {

            var data = socialData.shift();
            social.addItem(data);

          }

      };

      // add item to holder
      social.addItem = function (data) {

        var $template = social.cache.$feedTemplate.clone();

        // select template
        switch (data.type) {
          case 'twitter':
            $item = social.cache.$twitterTemplate.clone();
            break;
          case 'instagram':
            $item = social.cache.$instagramTemplate.clone();
            break;
          case 'facebook':
            $item = social.cache.$facebookTemplate.clone();
            break;
          case 'pinterest':
            $item = social.cache.$pinterestTemplate.clone();
            break;
        }

        if($item.length < 1) {
          $item = social.cache.$feedTemplate.clone();
        }

        // text
        var $text = $item.find('.text');
        if ($text.length) {
          if (data.text) {
            var text = data.text;
              wordsLimit = $text.data('words-limit'),
              wordsArray = text.split(' ');

            if(wordsLimit && wordsLimit > 0 && wordsLimit < wordsArray.length) {
              text = wordsArray.splice(0, wordsLimit).join(' ') + '...';
            }

            $text.html(text);

          } else {
            $text.remove();
          }
        }

        // photo
        var $photo = $item.find('.photo');
        if ($photo.length) {
          if (data.photo) {

            if ($photo.prop('tagName').toLowerCase() == 'img') {
              $photo.attr('src', data.photo);
            } else {
              $photo.css('background-image', 'url(' + data.photo + ')');
            }

          } else {
            $photo.remove();
            $item.addClass('no-photo');
          }
        }

        // likes
        var $likes = $item.find('.likes');
        if ($likes.length) {
          if (data.likes) {
            $likes.text(data.likes);
          } else {
            $likes.remove();
          }
        }

        // url
        var $url = $item.find('.url');
        if ($url.length && data.url) {
          $url.attr('href', data.url);
        }

        social.cache.$holder.append($item);

      };

      social.getData = function () {

        social.cache.typesCalled = 0;
        social.cache.typesActive = 0;

        for (var type in social.cache.TYPES) {
          // loop calls
          if (social.cache.TYPES[type] && social.cache.TYPES[type] == true) {
            social.cache.typesActive = social.cache.typesActive + 1;
            social.call(type);
          }
        }

      };

      social.call = function (type) {

        $.ajax({
          url: social.cache.API + type,
          data: {},
          dataType: 'jsonp',
          success: function(data) {
            social.data[type] = data;
          },
          complete: function(data) {

            social.cache.typesCalled = social.cache.typesCalled + 1;
            if(social.cache.typesCalled == social.cache.typesActive) {
              social.showItems();
            }

          }
        });

      };

      $(social.init);
};

esign.jsonFetcher = function() {
  function jsonFetcher(element, api) {
    $.get(api, function(data) {
        socialFeedRenderer(data, element);
    });
  }

  function socialFeedRenderer(data, element) {
    element.innerHTML = "";
    var template = document.querySelector('.json-fetcher__template');

    var keys = element.dataset.keys.split(';');

    data.data.forEach(function (item) {
        var templateClone = template.cloneNode(true);
        keys.forEach(function (key) {
            try {
                var keyParts = key.split('.');
                var index = 0;
                var data = item;
                while (index + 1 <= keyParts.length) {
                  data = data[keyParts[index]];
                  index += 1;
                }
                templateClone.innerHTML = templateClone.innerHTML.replace('__' + key + '__', data);
            } catch (error) {
                console.error(error);
            }
        });
        templateClone.innerHTML = templateClone.innerHTML.replace('data-src', 'src');
        element.appendChild(templateClone.children[0]);
    });

    var buttonPrev = $('<button data-api="'+ data.links.prev + '">prev</button>');
    if (!data.links.prev) {
        buttonPrev.attr('disabled', 'disabled');
    }

    var buttonNext = $('<button data-api="'+ data.links.next + '">next</button>');
    if (!data.links.next) {
        buttonNext.attr('disabled', 'disabled');
    }

  }

  var jsonHolder = document.querySelector('.json-fetcher');
  jsonFetcher(jsonHolder, jsonHolder.dataset.api);
};

esign.navigation = function() {
	var $mobileModaalTrigger = $('.main-nav__trigger');
	$mobileModaalTrigger.modaal({
    type: 'inline',
    animation_speed: 200,
    fullscreen: true,
    background: '#F7ECE8',
    custom_class: 'mobile-modaal-wrapper',
    before_open: function() {
      $mobileModaalTrigger.addClass('active');
    },
    before_close: function () {
      $mobileModaalTrigger.removeClass('active');
    }
	});
};

esign.responsiveVideos = function() {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function() {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

esign.initPromoAction = function() {
  var $promoActionTrigger = $('.js-promo-action-trigger');
  var $promoAction = $('#promo-action');

  // promo modal exists
  if ($promoActionTrigger.length > 0 && $promoAction.length > 0) {
    var cookieNamespace = 'promo-action';
    var cookieName = $promoAction.data('cookie');
    var showModal = true;
    var modalHeight = 360;

    // check if cookie exists & cookie == current modal cookie
    if (esign.readCookie(cookieNamespace) &&
        esign.readCookie(cookieNamespace) === cookieName) {
      showModal = false;
    }

    if ($(window).width() > 767) {
      modalHeight = 400;
    }

    if (showModal) {
      setTimeout(function() {
        $promoActionTrigger.modaal({
          type: 'inline',
          animation_speed: 200,
          background: '#fff',
          overlay_opacity: 0.5,
          width: 800,
          height: modalHeight,
          custom_class: 'promo-modaal-wrapper',
          before_close: function () {
            // console.log('close');
            esign.createCookie(cookieNamespace, cookieName, 1000);
          }
        }).trigger('click');
      }, 20000);
    }
  }
};

esign.createCookie = function(name,value,days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";
  document.cookie = name+"="+value+expires+"; path=/";
}

esign.readCookie = function(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

esign.productPage = function() {
  var $productPrice = $('.product__price');

  $('.js-change-price').change(function(e) {
    if (window.productPrices) {
      var val = $(this).val();
      var price = window.productPrices[val];

      $productPrice.text(price);
    }
  });
};

esign.fancybox = function() {
  var lang = $('html').attr('lang');
  if (lang !== 'nl' && lang !== 'fr') {
    lang = 'en';
  }

  $('.fancybox').fancybox({
    openEffect : 'elastic',
    closeEffect : 'none',
    loop : false,
    lang: lang,
    i18n : {
      'nl' : {
        CLOSE       : 'Sluiten',
        NEXT        : 'Volgende',
        PREV        : 'Vorige',
        ERROR       : 'De gevraagde inhoud kan niet worden geladen. <br/> Probeer het later opnieuw.',
        PLAY_START  : 'Begin diavoorstelling',
        PLAY_STOP   : 'Pauze diavoorstelling',
        FULL_SCREEN : 'Volledig scherm',
        THUMBS      : 'Thumbnails',
        DOWNLOAD    : 'Download',
        SHARE       : 'Delen',
        ZOOM        : 'Zoom'
      },
      'fr' : {
        CLOSE       : 'Fermer',
        NEXT        : 'Prochain',
        PREV        : 'Précédent',
        ERROR       : 'Le contenu demandé ne peut pas être chargé. <br/> Veuillez réessayer plus tard.',
        PLAY_START  : 'Lancer le diaporama',
        PLAY_STOP   : 'Suspendre le diaporama',
        FULL_SCREEN : 'Plein écran',
        THUMBS      : 'Thumbnails',
        DOWNLOAD    : 'Télécharger',
        SHARE       : 'Partager',
        ZOOM        : 'Zoom'
      },
      'en' : {
        CLOSE       : 'Close',
        NEXT        : 'Next',
        PREV        : 'Previous',
        ERROR       : 'The requested content cannot be loaded. <br/> Please try again later.',
        PLAY_START  : 'Start slideshow',
        PLAY_STOP   : 'Pause slideshow',
        FULL_SCREEN : 'Full screen',
        THUMBS      : 'Thumbnails',
        DOWNLOAD    : 'Download',
        SHARE       : 'Share',
        ZOOM        : 'Zoom'
      }
    }
  });
};

esign.lang = function() {
  $('.lang__trigger').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    $this.toggleClass('active').next().toggle('fast');
  });
}

esign.checkout = function() {
  var $billingForm = $('.billing-form');

  $('.billing-form-opts input[type="radio"]').change(function(e) {
    if (e.target.value === 'other') {
      $billingForm.show('fast');
    } else {
      $billingForm.hide('fast');
    }
  });
}

esign.accordion = function () {
  $('.accordion__title').click(function(e) {
    e.preventDefault();
    $(this).toggleClass('active').next('.accordion__content').toggle('fast');
  });
}

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

esign.initCart = function() {
  esign.cartController = new CartController();
  esign.cartController.get();
};

esign.initOrderSummary = function() {
  esign.orderSummaryController = new OrderSummaryController();
};

esign.initNewsletter = function() {
  esign.newsletterController = new NewsletterController();
  esign.newsletter = new Newsletter();
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'response.js/response',
  '@fancyapps/fancybox/dist/jquery.fancybox',
  'modaal/dist/js/modaal',
  'client/libs/validation/jquery.validationEngine',

  'client/feedback',
  'client/Request',
  'client/RequestV2',
  'client/CartController',
  'client/Cart',
  'client/OrderSummaryController',
  'client/OrderSummary',
  'client/NewsletterController',
  'client/Newsletter',

  'client/CheckoutIndex',
  'client/CartIndex'
], function() {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/client/esign", function(){});

