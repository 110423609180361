/**
 * Created by Bart Decorte
 * Date: 10/04/2018
 * Time: 16:21
 */

var esign = esign || {};

esign.renderErrorBox = function(errors) {
  var html = '';
  var key, i;
  for (key in errors) {
    if (errors.hasOwnProperty(key)) {
      for (i = 0; i < errors[key].length; i++) {
        html += '<li>' + errors[key][i] + '</li>';
      }
    }
  }
  return '<ul>' + html + '</ul>';
};

esign.renderSuccessBox = function(msg) {
  return '<p>' + msg + '</p>';
};
define("client/feedback", function(){});

