/**
 * Created by Bart Decorte
 * Date: 05/02/2018
 * Time: 13:48
 */

function Newsletter() {
  this.bindListeners();
  this.$root = $('.newsletter-form');
}

Newsletter.prototype.bindListeners = function() {
  var scope = this;
  var $body = $('body');
  $body.on('submit', '.js-newsletter-form', function(ev) { return scope.lNewsletterFormSubmitted(ev); })
};

Newsletter.prototype.lNewsletterFormSubmitted = function(ev) {
  var scope = this;
  ev.preventDefault();
  scope.clearFeedback();

  var $form = $(ev.target).get(0).tagName === 'FORM' ? $(ev.target) : $(ev.target).parents('form').first();
  var email = $form.find('input[name="email"]').first().val();

  var $fullName = $form.find('[name="full_name"]');
  var $firstName = $form.find('[name="first_name"]');
  var $name = $form.find('[name="name"]');
  var fullName = $fullName.length ? $fullName.val() : null;
  var firstName = $firstName.length ? $firstName.val() : null;
  var name = $name.length ? $name.val() : null;

  var onSuccess = function(data) {
    scope.showSuccess(data.response);
  };

  var onError = function(errors) {
    scope.showErrors(errors);
  };

  esign.newsletterController.post(email, fullName, firstName, name, onSuccess, onError);
  return false;
};

Newsletter.prototype.showSuccess = function(msg) {
  var scope = this;
  var html = esign.renderSuccessBox(msg);
  scope.$root.find('.note--success').html(html).show();
  console.log(scope.$root.find('.note--success'));
  return this;
};

Newsletter.prototype.showErrors = function(errors) {
  var scope = this;
  var html = esign.renderErrorBox(errors);
  scope.$root.find('.note--error').html(html).show();
  console.log(scope.$root.find('.note--error'));
};

Newsletter.prototype.clearFeedback = function() {
  var scope = this;
  scope.$root.find('.note').hide();
  return this;
};

define("client/Newsletter", function(){});

