/**
 * Created by Bart Decorte
 * Date: 26/01/2018
 * Time: 11:16
 */

function OrderSummary() {
  var scope = this;
  this.$root = $('.js-order-summary');

  this.shippingCountry = $('.js-country--shipping').val();
  this.billingCountry = $('.js-country--billing').val();
  this.sameAddress = undefined;
  this.shippingMethodId = undefined;

  scope.updateShippingMethodIdValue();
  scope.updateSameAddressValue();
  scope.bindListeners();
}

OrderSummary.prototype.bindListeners = function() {
  var scope = this;
  var $body = $('body');
  $body.on('change', '.js-country', function(ev) { return scope.lCountryChanged(ev); });
  $body.on('change', '.js-same-address', function(ev) { return scope.lSameAddressChanged(ev); });
  $body.on('change', '.js-shipping-method', function(ev) { return scope.lShippingMethodChanged(ev); });
};

OrderSummary.prototype.lCountryChanged = function(ev) {
  var scope = this;
  var $elt = $(ev.target);

  if ($elt.hasClass('js-country--shipping')) {
    scope.shippingCountry = $elt.val();
  } else {
    scope.billingCountry = $elt.val();
  }
  scope.updateShipping();
};

OrderSummary.prototype.lSameAddressChanged = function(ev) {
  var scope = this;
  scope.updateSameAddressValue();
  scope.updateShipping();
};

OrderSummary.prototype.lShippingMethodChanged = function(ev) {
  var scope = this;
  scope.updateShippingMethodIdValue();
  scope.updateShipping();
};

OrderSummary.prototype.updateSameAddressValue = function() {
  var scope = this;
  var $radios = $('.js-same-address');
  scope.sameAddress = parseInt($radios.filter(':checked').first().val());
};

OrderSummary.prototype.updateShippingMethodIdValue = function() {
  var scope = this;
  var $hidden = $('[name="shipping_method_id"][type="hidden"]');
  if ($hidden.length) {
    scope.shippingMethodId = parseInt($hidden.val());
  } else {
    var $radios = $('.js-shipping-method');
    var $checked = $radios.filter(':checked').first();
    scope.shippingMethodId = parseInt($checked.val());
  }
};

OrderSummary.prototype.getShippingCountryId = function() {
  var scope = this;
  if (scope.sameAddress) console.log('same address');
  if (!scope.sameAddress) console.log('not same address');
  if (scope.sameAddress) return scope.billingCountry;
  return scope.shippingCountry;
};

OrderSummary.prototype.render = function(shipping, total, vat, availableShippingMethods) {
  var scope = this;
  var $wrap = $('.js-order-summary');
  $('.js-shipping-cost').html(shipping);
  $('.js-total-cost').html(total);

  $('.js-vat-wrap').hide();
  var key, $target;
  for (key in vat) {
    if (vat.hasOwnProperty(key)) {
      $target = $wrap.find('.js-vat-' + key);
      if ($target.length) {
        $target.html(vat[key]['amountFormatted']);
        if (vat[key]['amount'] > 0) $target.parents('.js-vat-wrap').first().show();
      }
    }
  }

  scope.enableShippingMethods(availableShippingMethods);

  return scope;
};

OrderSummary.prototype.enableShippingMethods = function(availableShippingMethods) {
  var scope = this;

  var unavailableSelector = '.js-shipping-method-unavailable';
  var $shippingMethods = $('[name="shipping_method_id"]');
  var $oldActive = $shippingMethods.filter(':checked').first();
  var i, $shippingMethod;
  for (i = 0; i < $shippingMethods.length; i++) {
    $shippingMethod = $($shippingMethods[i]);
    $shippingMethod.prop('disabled', true);
    $shippingMethod.parent().find(unavailableSelector).show();
  }

  var $elt;
  for (i = 0; i < availableShippingMethods.length; i++) {
    $elt = $shippingMethods.filter('[value="' + availableShippingMethods[i] +'"]');
    if ($elt.length) {
      $elt.prop('disabled', false);
      $elt.parent().find(unavailableSelector).hide();
    }
  }
  if ($oldActive.is(':disabled')) {
    var $newActive = $shippingMethods.filter('[value="' + availableShippingMethods[0] + '"]');
    $newActive.prop('checked', true).trigger('change');
  }
  return scope;
};

OrderSummary.prototype.updateShipping = function() {
  var scope = this;
  var onSuccess = function(data) {
    scope.render(data.shippingFormatted, data.totalFormatted, data.vat, data.availableShippingMethods);
  };

  esign.orderSummaryController.getShipping(scope.getShippingCountryId(), scope.shippingMethodId, onSuccess);
};
define("client/OrderSummary", function(){});

