/**
 * Created by Bart Decorte
 * Date: 10/01/2018
 * Time: 10:46
 */

function CartIndex() {
  var scope = this;
  scope.delay = 0; // ms
  scope.timeout = undefined;
  scope.bindListeners();
}

CartIndex.prototype.bindListeners = function() {
  var scope = this;
  $('.js-cart').on('change', 'input, select', function(ev) { return scope.lProductInputChanged(ev); })
};

CartIndex.prototype.lProductInputChanged = function(ev) {
  var scope = this;

  if (typeof scope.timeout !== typeof undefined) {
    clearTimeout(scope.timeout);
    scope.timeout = undefined;
  }

  scope.timeout = setTimeout(scope.submitForm, scope.delay);
};

CartIndex.prototype.submitForm = function() {
  $('.js-cart').submit();
};
define("client/CartIndex", function(){});

