/**
 * Created by Bart Decorte
 * Date: 26/01/2018
 * Time: 11:19
 */

function OrderSummaryController() {
  this.endpoint = 'shipping-totals';
}

OrderSummaryController.prototype.getShipping = function(countryId, shippingMethodId, onSuccess)
{
  var scope = this;
  if (typeof onSuccess === typeof undefined) onSuccess = function() {};

  var request = new Request(scope.endpoint, 'GET', {country_id: countryId, shipping_method_id: shippingMethodId},
    onSuccess);
  request.execute();
};
define("client/OrderSummaryController", function(){});

