/**
 * Created by Bart Decorte
 * Date: 19/12/2017
 * Time: 11:35
 */

function Request(endpoint, method, data, onSuccess, onError, onComplete)
{
  this.apiPrefix = 'api/v1';
  this.endpoint = endpoint;
  this.method = method;
  this.data = typeof data !== typeof undefined ? data : {};
  this.onSuccess = typeof onSuccess !== typeof undefined ? onSuccess : function() {};
  this.onError = typeof onError !== typeof undefined ? onError : function() {};
  this.onComplete = typeof onComplete !== typeof undefined ? onComplete : function() {};
}

Request.prototype.url = function() {
  var scope = this;
  return esign.info.base_url + '/' + scope.apiPrefix + '/' + scope.endpoint;
};

Request.prototype.execute = function() {
  var scope = this;

  var data = scope.data;

  data.locale_code = esign.info.locale;
  data.currency_code = esign.info.currency;

  $.ajax({
    headers: {
      'X-CSRF-TOKEN': esign.info.csrf
    },
    type: scope.method,
    url: scope.url(),
    data: scope.data,
    dataType: 'json',
    success: function(response) {
      if (!response.hasOwnProperty('errors') || !Object.keys(response.errors).length) {
        scope.onSuccess(response.data);
      } else {
        scope.onError(response.errors);
      }
    },
    error: function (jqXHR) {
      scope.onError(jqXHR.responseJSON.errors);
    },
    complete: function() {
      scope.onComplete();
    }
  });
};
define("client/Request", function(){});

