/**
 * Created by Bart Decorte
 * Date: 19/12/2017
 * Time: 11:17
 */

function CartController() {
  this.endpoint = 'cart';

  this.doNothing = function() {};
}

CartController.prototype.defaultGetSuccessCallBack = function(data) {
  if (typeof esign.cart !== typeof undefined) {
    esign.cart.draw(data.items, data.totalFormatted);
    esign.cart.setCount(data.count);
  }
};

CartController.prototype.defaultAddSuccessCallBack = function(data) {
  if (typeof esign.cart !== typeof undefined) {
    esign.cart.draw(data.items, data.totalFormatted);
    esign.cart.setCount(data.count);
  }
  if (typeof esign.drawers !== typeof undefined) {
    esign.drawers.right.open();
  }
};

CartController.prototype.get = function(onSuccess) {
  // GET
  var scope = this;
  if (typeof onSuccess === typeof undefined) onSuccess = scope.defaultGetSuccessCallBack;
  var success = function(data) {
    onSuccess(data);
  };
  var request = new Request(scope.endpoint, 'GET', undefined, success);
  request.execute();
};

CartController.prototype.add = function(productId, amount, options, onSuccess) {
  // PUT
  var scope = this;
  if (typeof options === typeof undefined) options = {};
  if (typeof onSuccess === typeof undefined) onSuccess = scope.defaultAddSuccessCallBack;
  var success = function(data) {
    scope.gtmAddToCart(productId, amount, data);
    onSuccess(data);
  };

  var request = new Request(scope.endpoint, 'PUT', {product_id: productId, amount: amount, options: options}, success);
  request.execute();
};

CartController.prototype.update = function(index, amount, onSuccess) {
  // UPDATE
  var scope = this;
  if (typeof onSuccess === typeof undefined) onSuccess = scope.defaultAddSuccessCallBack;
  var success = function(data) {
    onSuccess(data);
  };

  var request = new Request(scope.endpoint, 'PATCH', {index: index, amount: amount}, success);
  request.execute();
};

CartController.prototype.delete = function(index, onSuccess) {
  // DELETE
  var scope = this;
  if (typeof onSuccess === typeof undefined) onSuccess = scope.defaultAddSuccessCallBack;
  var success = function(data) {
    onSuccess(data);
  };

  var request = new Request(scope.endpoint, 'DELETE', {index: index}, success);
  request.execute();
};

CartController.prototype.gtmAddToCart = function(productId, amount, data) {
  data.items.forEach(function(item) {
    if(item.id == productId) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
              'name': item.nameDefaultLocale,
              'id': item.sku,
              'price': (item.prices.total / item.amount),
              'brand': item.brand,
              'category': item.category,
              'quantity': amount
            }]
          }
        }
      });
    }
  });
};

CartController.prototype.updateRemarks = function(remarks) {
  var scope = this;

  var success = function() { console.log('success'); };
  var request = new RequestV2(scope.endpoints.remarks, 'PUT', {remarks: remarks}, success);
  request.execute();
};

define("client/CartController", function(){});

